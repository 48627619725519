@import "vars", "reset", "mixins";

.wrapper { max-width: $default-width; margin: 0 auto; padding: 80px 20px; }

.user-profile { position: relative; float: left; width: 28%; margin-right: 4%; padding: 20px 0 0; text-align: center;
	& > * { display: block; }
	.avatar { max-width: 140px; max-height: 140px; border-radius: 50%; margin: 0 auto 25px; }
	.my-name { font-size: 24px; font-weight: 700; line-height: 30px; color: $grey-color-dark; }
	.my-job-title { font-size: 14px; font-weight: 300; margin: 5px 0 0; }
	.mainmenu { margin: 40px 0; text-align: center;
		a { display: block; max-width: 300px; margin: 0 auto; padding: 15px 0; border: 1px solid $grey-color-lightest; background: $background-color; box-shadow: 0 2px 3px rgba($black,.05); @include transition(all, .1s, linear);
			&:hover, &.active { background: darken($background-color, 1%); border-left: 4px solid $grey-color-lightest; }
		}
	}
	.about-me { font-size: 16px; line-height: 26px; margin: 30px 0 0; }
	ul { font-size: 0; margin: 25px 0 0;
		li { display: inline-block; margin: 5px;
			a { position: relative; display: block; width: 40px; height: 40px; line-height: 40px; background: darken($grey-color-lightest, 6%); border-radius: 50%;
				&:hover { background: $primary-color; }
				svg { display: inline-block; vertical-align: middle;
					* { fill: $white;  }
				}
			}
		}
	}
}

.the-content { float: left; width: 68%;
	& > h1 { text-transform: uppercase; color: $grey-color-light; letter-spacing: 0.1em; font-size: 12px; font-weight: 400; margin: 0 0 20px; text-align: center; }
	.search { position: relative; margin: 20px 0; border: 1px solid $grey-color-lightest; background: $white; box-shadow: 0 2px 3px rgba($black,.05), inset 6px 0 0 $grey-color-lightest; @include placeholder($grey-color-lighter, italic);
		& > div { overflow: hidden;
			.show-results-count { float: left; margin-left: -46%; padding: 0 20px; min-height: 55px; line-height: 55px; color: $grey-color-lighter; background: $white; letter-spacing: 0.05em; font-size: 11px; text-transform: uppercase; box-shadow: 6px 0 0 0 $grey-color-lightest; @include transition(all, .2s, linear);
				&.active { margin-left: 0; }
			}
			.search-holder { float: left; width: 60%; margin: 0 0 0 34px; background: url(../svg/search-icon.svg) no-repeat left center; @include bgsizeNum(15px, 16px); padding: 0 0 0 25px;
				input { float: left; width: 100%; min-height: 55px; line-height: 22px; background: none; border: none; }
			}
		}
		.results-container { position: absolute; left: -1px; width: calc(100% + 2px); padding: 1px; box-shadow: inset 0 0 0 1px $grey-color-lightest, 0 7px 10px rgba($black,.05); z-index: 1000;
			li { font-size: 15px;
				a { position: relative; display: block; padding: 15px 15px 15px 35px; background: $background-color; box-shadow: 0 -1px 0 $grey-color-lightest;
					&:before { position: absolute; top: 50%; left: 15px; background: $grey-color-normal; content: ""; width: 5px; height: 5px; border-radius: 50%; @include translate(0, -50%); }
					&:hover { background: $white; }
				}
			}
		}
	}
	.post { position: relative; margin: 40px 0 0; padding: 40px 40px 80px; border: 1px solid $grey-color-lightest; background: $white; box-shadow: 0 2px 3px rgba($black,.05);
		&.page { padding: 40px; }

		&:first-of-type { margin: 0; }

		.post-header {
			ul { margin: 0 0 4px;
				li { position: relative; float: left; margin: 0 0 0 10px; padding: 0 0 0 12px; text-transform: uppercase; font-size: 13px; letter-spacing: 0.05em; color: $grey-color-light;
					&:first-of-type { margin: 0; padding: 0;
						&:before { content: none; }
					}
					&:before { position: absolute; top: 50%; left: 0; width: 3px; height: 3px; border-radius: 50%; background: $grey-color-lighter; content: ""; @include translate(0, -50%); }
					&.cats a { color: $grey-color-light;
						&:last-of-type:after { content: none; }
						&:after { content: ","; }
						&:hover { color: $primary-color; border-bottom: 1px solid $primary-color; }
					}
				}
			}
			h1, h2 { font-size: 30px; font-weight: 900; margin: 0 0 20px; color: $grey-color-dark;
				a { color: $grey-color-dark;
					&:hover { color: $primary-color; }
				}
			}
		}

		.post-content { font-size: 16px; line-height: 26px;
			abbr { border-bottom: 1px dotted $grey-color-normal; cursor: help; }
			code, kbd, pre { margin: 0; font-family: monospace; word-wrap: break-word; word-break: break-word; white-space: pre-wrap; }
			address, cite, var { font-style: italic; }
			blockquote { margin: 40px 0; padding-left: 30px; font-style: italic; border-left: 3px solid $grey-color-light; }

			em, strong { color: $grey-color-dark; }

			h1, h2, h3, h4, h5, h6 { margin: 30px 0; color: $grey-color-dark; }
			h1 { font-size: 30px; }
			h2 { font-size: 26px; }
			h3 { font-size: 22px; }
			h4 { font-size: 20px; }
			h5 { font-size: 18px; }
			h6 { font-size: 16px; }

			a { color: $primary-color; border-bottom: 1px solid $primary-color;
				&:hover { border: none; }
			}

			ul, ol { margin: 15px 0;
				li { margin: 5px 0;
					& > code { font-size: 14px; padding: 3px 6px; background: $background-color; border: 1px solid $grey-color-lightest; }
				}
			}

			dl { margin: 40px 0;
				dt { margin: 10px 0 0; font-size: 18px; font-weight: 700; }
				dd { margin: 5px 0;
					& > code { font-size: 14px; padding: 3px 6px; background: $background-color; border: 1px solid $grey-color-lightest; }
				}
			}

			ul { list-style-type: disc; list-style-position: inside; }

			p { margin: 15px 0; line-height: 27px;
				& > code { font-size: 14px; padding: 3px 6px; background: $background-color; border: 1px solid $grey-color-lightest; }
			}

			.alignleft { float: left; margin: 20px 30px 20px 0; }
			.alignright { float: right; margin: 20px 0 20px 20px; }
			.aligncenter { clear: both; margin: 0 auto 20px; }

			figcaption { color: $grey-color-light; font-size: 13px; font-style: italic; padding-top: 10px; }

			@import "syntax";
		}

		.post-footer { position: absolute; bottom: 0; left: 0; width: 100%; border-top: 1px solid $grey-color-lightest; background: lighten($grey-color-lightest, 5%);
			div { float: left; min-height: 48px; border-right: 1px solid $grey-color-lightest; font-size: 11px; line-height: 48px; text-transform: uppercase; color: $grey-color-light;
				&.mood { padding: 0 40px 0 20px; z-index: 10; background: lighten($grey-color-lightest, 5%);
					&.happy { background: url(../svg/mood-icons.svg#smiley-happy) no-repeat calc(100% - 20px) center; @include bgsizeNum(12px, 12px); }
					&.sad { background: url(../svg/mood-icons.svg#smiley-sad) no-repeat calc(100% - 20px) center; @include bgsizeNum(12px, 12px); }
					&.angry { background: url(../svg/mood-icons.svg#smiley-angry) no-repeat calc(100% - 20px) center; @include bgsizeNum(12px, 12px); }
					&.speechless { background: url(../svg/mood-icons.svg#smiley-speechless) no-repeat calc(100% - 20px) center; @include bgsizeNum(12px, 12px); }
				}

				&.share { position: relative; z-index: 10; padding: 0 20px 0 40px; background: lighten($grey-color-lightest, 5%) url(../svg/share-icon.svg) no-repeat 20px center; @include bgsizeNum(10px, 10px); cursor: pointer;
					&:hover { background-color: $white; }
					&.active { background-color: $white;
						.social-networks { display: block; }
					}
					.social-networks { display: none; position: absolute; top: -48px; left: 50%; width: 100px; box-shadow: 0 0 0 1px $grey-color-lightest, 0 3px 4px rgba($black,.05); @include translate(-50%, 0);
						li { position: relative; float: left; width: 50%; min-height: 50px; background: $white; text-align: center; box-shadow: inset 1px 0 0 $grey-color-lightest;
							&:first-of-type { box-shadow: none; }
							&.share-facebook:hover { background: $facebook-brand-color; }
							&.share-twitter:hover { background: $twitter-brand-color; }
							&:hover a svg { fill: $white; }
							a { position: absolute; width: 100%; height: 100%; left: 0; top: 0;
								svg { display: inline-block; vertical-align: middle; }
							}
						}
					}
				}
				&.tags { position: absolute; right: 0; width: 100%; float: right; padding: 0 20px; border: none; text-transform: lowercase; text-align: right;
					ul li { display: inline;
						&:last-child:after { content: none; }
						&:after { content: ","; }
						a { color: $grey-color-light;
							&:hover { border-bottom: 1px solid $grey-color-light; }
						}
					}
				}
			}
		}
	}

	.comments { position: relative; margin: 40px 0 0; padding: 40px; border: 1px solid $grey-color-lightest; background: $white; box-shadow: 0 2px 3px rgba($black,.05); }

	.pagination { position: relative; margin: 40px 0 0;
		.previous { float: left; }
		.next { float: right; }
		.previous, .next { padding: 14px 20px; border: 1px solid $grey-color-lightest; background: $white; box-shadow: 0 2px 3px rgba($black,.05); text-transform: uppercase; font-size: 14px; font-weight: 900; color: $grey-color-normal;
			&:hover { background: $primary-color; color: $white; }
		}
		.page_number { position: absolute; left: 50%; top: 50%; @include translate(-50%, -50%); font-size: 12px; color: $grey-color-light; }
	}
}

.not-found { text-align: center;
	&.post { padding: 40px; font-size: 16px; }
}

.subscribe {
  background: #333;
  border-bottom: 1px solid #ffffff;
  color: #fff;
  padding-bottom: 2.5em;
  padding-top: 2.5em;
  text-align: center; }
  

.subscribe-title {
  font-size: 1.66667em;
  margin: 0 0 1.33333rem; }

.subscribe-text {
  font-size: 0.88889em;
  margin-bottom: 1.66667rem; }

.subscribe-form {
  margin-bottom: 1.66667rem;
  position: relative; }
  .subscribe-form input[type="email"] {
      background-color: #fff; 
      margin-top: 20px;}
  .subscribe-form .button {
      display: block;
      width: 100%; 
      background-color: #fffb00;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;}
      

@media (min-width: 481px) {
  .subscribe-form input[type="email"] {
      height: 3.75em;
      padding-right: 145px; }
  .subscribe-form .button {
      height: 3em;
      padding-left: 0;
      padding-right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      width: 130px; } }

@media (min-width: 801px) {
  .subscribe {
      padding-bottom: 3.33333em;
      padding-top: 3.33333em; } }

@import "responsive";