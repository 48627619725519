@include media-query($on-tablet) {
	body { box-shadow: inset 0 10px 0 $primary-color; }
	.wrapper { padding: 50px 20px; }
	.user-profile { position: static !important; width: 100%; margin: 0 0 50px;
		.about-me { width: 80%; margin: 30px auto 0; }
	}
	.the-content { width: 100%; }
	.backtotop { width: 30px !important; height: 30px !important; bottom: 10px !important; right: 10px !important; background-size: 50% !important; }
}

@include media-query($on-smaller-tablets) {
	.the-content .post { padding: 20px 20px 120px;
		.post-content {
			.alignleft, .alignright { float: none; margin: 20px 0;
				img { width: 100%; }
			}
		}
	}
}

@include media-query($on-phone) {
	.the-content .search > div .search-holder { width: 45%; }
	.the-content .post .post-footer div.tags { position: static; text-align: left; border-top: 1px solid $grey-color-lightest; }
}